import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Logo from "../components/UX/Logo"
import Section from "../components/UX/Section"
import { RevealTitle } from "../components/UX/VisibilitySensor"
import { PageButton } from "../components/UI/Buttons"

const Contact = () => {
  return (
    <Layout>
      <Seo title="404 - Not found" />
      <Section>
        <div className="container grid-by-4">
          <Logo />
          <div className="grid-right">
            <RevealTitle barStyle="right" directionUp={false}>
              <h1 style={{ textAlign: "left" }}>
                Sorry this page does not exist
              </h1>
              <br></br>
              <p
                style={{
                  gridRowStart: "2",
                  gridColumnStart: "1",
                  alignItems: "end",
                }}
              >
                Let's get you back to the home page and find what you are
                looking for? Click this button to return to the home page.
              </p>
              <PageButton linkRef="/" class="footer small-text">
                HOME
              </PageButton>
            </RevealTitle>
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export default Contact
